@function HexToRGB($hex)
    @return red($hex), green($hex), blue($hex)

// :root
:root
    --black: #000
    --superBlack: #000
    --white: #fff
    --primary: #0AAF60
    --primaryLight: #0AAF60
    --brand-primary-light: #0AAF60
    --primaryDarken: #0A9753

    --secondary: #C8A900
    --gray800: #757575
    --gray750: #999999
    --gray700: #BABABA
    --gray600: #DDD
    --gray550: #D9D9D9
    --gray500: #E8E8E8
    --gray400: #F3F3F3
    --gray300: #F9F9F9
    --gray300-rgb: #{HexToRGB(#f9f9f9)}
    --CTA: #FF7325
    --CTAHover: #E26722
    --brown300: #947814
    --success: #2CBA54
    --successLight: #8CfAb4
    --error: #F43838
    --link: #324AC8
    --blue: #2A3EA7
    --blue-dashboard: #3056E0
    --blue400: #E7F7EF
    --brand-secondary: #FFD600
    --yellow: #FFD600
    --neutral: #EAECF6
    --negative: #FEEBEB
    --attention: #FFF1E9

    --avatar: #44B700
    --grayE3CC: #E3E3E3CC
    --black111: #111e
    --black555: #555
    --black02: #0002
    --grayFA: #FAFAFA
    --muiError: #F44336
    --gray19: #191919
    --grayLight: #D3D3D3
    --gray: #808080
    --grayE5: #EEE5
    --grayE8: #EEE8
    --grayE: #EEE
    --grayC5: #CCC5
    --grayC0: #C0C0C0
    --AA7575: #AA7575
    --red: #F00

// .darkMode
.DarkMode
    --black: #fff
    --superBlack: #000
    --white: #000
    --primary: #57E596
    //#3CB371
    --primaryLight: #3CB371
    --brand-primary-light: #3CB371
    --primaryDarken: #368358

    --secondary: #FFD700
    --gray800: #BDBDBD
    --gray750: #A6A6A6
    --gray700: #8C8C8C
    --gray600: #999
    --gray550: #8F8F8F
    --gray500: #838383
    --gray400: #3a3a3a
    --gray300: #141414
    --gray300-rgb: #{HexToRGB(#141414)}
    --CTA: #D65A1F
    // #B34A1F #8C3B1C #C04F1A
    --CTAHover: #8C3B1C
    --brown300: #6B87EB
    --success: #249D47
    // #1E7D3D #176B34 #1F8F45
    --successLight: #73054B
    --error: #FF3B30
    --link: #0BC7C7
    // #5a6fdb #3F6FD8 #6A89FF
    --blue: #D5C158
    --blue-dashboard: #CFA91F
    --blue400: #18100F
    --brand-secondary: #0022FF
    --yellow: #0022FF
    --neutral: #363D50
    --negative: #2E2E2E
    --attention: #5C4B3E

    --avatar: #00FF44
    --grayE3CC: #8C8C8CCC
    --black111: #EEEE
    --black555: #888
    --black02: #0202
    --grayFA: #050505
    --muiError: #FF8888
    --gray19: #E0E0E0
    --grayLight: #1D1D1D
    --gray: #666
    --grayE5: #222
    --grayE8: #333
    --grayE: #444
    --grayC5: #555
    --grayC0: #777
    --AA7575: #7575AA
    --red: #D32F2F
    // #D32F2F #B71C1C #FF3B30 #E53935
